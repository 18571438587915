html{
  scroll-behavior: smooth;
}

.App {
  text-align: center;

}



a{
  color: slategrey;
}
a:hover{
  color: rgb(0,150,150);
}

h1{
  text-shadow: 5px 5px 7px black;
}


#scroll-dialog-description{
  white-space: pre-line;
}

.moo{
  z-index: 999;
 width: 30vmin;
  position:fixed;
  bottom:0;
  left: 0;
}

.link-button:hover{
  animation: button-wiggle 0.25s linear;
}

@media (prefers-reduced-motion: no-preference) {
  .moo {
    animation: App-logo-hello infinite 30s linear;
  }
}

@keyframes button-wiggle {
0%, 50%, 100%{
  transform: translateX(0px);
}
25%{
  transform: translateX(0.05rem);
}
75%{
  transform: translateX(-0.05rem);
}
}

.container {
  background-image: url("./images/cassanicampbell.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: floralwhite;
}

.vid-grid{
  max-width: 80%;
}


Footer{
  height: 2rem;
  background-image: url("./images/cassanicampbell.jpg");
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid;
  border-color: white;
}

.footer{
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  color: white;
}

.footer a {
  color: yellow;
}

.footer a:hover {
  color: dodgerblue;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-hello {
  0% {
    bottom: -20rem;
  }
  25% {
    bottom: 0;
  }
  100% {
    bottom: -50rem;
  }
}
